<template>
<!-- 搜索栏 -->
    <div class="search">
        <div class="clearfix">
            <div class="fl">
                <span>提示：单击题面可显示答案和解析</span>
            </div>
            <div class="fr">
                <span class="qiehuan" @click="qiehuan">
                    <!-- <svg class="alicon" aria-hidden="true" v-if="this.radio">
                        <use xlink:href="#iconweixuanzhong"></use>
                    </svg>
                    <svg class="alicon" aria-hidden="true" v-else>
                        <use xlink:href="#iconxuanzhong"></use>
                    </svg> -->
                    显示全部答案与解析
                </span>
                <span>共<span>{{listconhttpnum}}</span>道试题</span>
                <el-button type="success" @click="quanbu" v-if="centre">全部加入试卷</el-button>
                <el-button @click="quanbu" class="jinzhi" v-else>全部加入试卷</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import VueEvent from "@/api/eventBus.js";
export default {
    props:['listconhttpnum'],
    data(){
        return{
            radio: true,
            paperlist:[],
            centre:true,
            status:[]
        }
    },
    mounted(){
        var that = this
        VueEvent.$off('huifu')
        VueEvent.$on('huifu',function(data){
            that.quanbuqh(data)
        })
    },
    watch:{
    },
    methods: {
        qiehuan(){
            this.radio = !this.radio
            var that = this
            if(this.radio == false){
                that.status = []
                JSON.parse(localStorage.getItem("paperlist")).forEach(function (res) {
                    that.status.push({status:1})
                });
                VueEvent.$emit('daanzhankai', that.status)
            } else{
                that.status = []
                JSON.parse(localStorage.getItem("paperlist")).forEach(function (res) {
                    that.status.push({status:2})
                });
                VueEvent.$emit('daanzhankai', that.status)
            }
        },
        quanbuqh(data){
            this.centre = data
        },
        quanbu(){
            var that = this
            that.centre = false
            that.paperlist = JSON.parse(localStorage.getItem("paperlist"))
            VueEvent.$emit('quanbushiti', that.paperlist)
        },
    },
}
</script>

<style lang="less" scoped>
.search{
    margin:24px 0;
    background: #fff;
    box-shadow: 0px 0px 6px 0px 
		rgba(200, 200, 200, 0.47);
    div{
        min-height: 50px;
        padding: 0 10px;
        .qiehuan{
            cursor: pointer;
        }
        div:first-child{
            input{
                margin-top: 7px;
                width: 189px;
                height: 34px;
                background-color: #e8eef7;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border: solid 1px #d0d7e1;
                border-right: none!important;
                text-indent: 1em;
            }
            i{
                background-color: #3688fc;
                color: #fff;
                width: 34px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                vertical-align: middle;
                border: solid 1px #d0d7e1;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-left: none!important;
            }
            span{
                padding-left: 12px;
                display: inline-block;
                line-height: 50px;
                font-size: 12px;
                color: #a3a3a3;
            }
        }
        div:last-child{
            line-height: 50px;
            span:first-child{
                margin: auto;
                display: inline-block;
                line-height: 14px;
                font-size: 12px;
                color: #3587fc;
                padding: 0 10px;
                border-right: 1px solid #eee;
            }
            span:nth-child(2){
                font-size: 12px;
                padding: 0 10px;
                span{
                    color: #3587fc;
                    padding: 0;
                }
            }
        }
    }
    .jinzhi{
        pointer-events: none;
        background-color: #eee;
        border: none;
    }
}

</style>
