<!--章节右侧 -->
<template>
  <div class="box_one">
    <div class="box_one_son">
      <ul>
        <li class="clearfix">
          <span class="fl">学段 :</span>
          <el-select v-model="xueduan" @change="chosegrade" class="fr">
            <el-option
              v-for="res in stage"
              :key="res.gsid"
              :label="res.gsname"
              :value="res.gsid"
            >
            </el-option>
          </el-select>
        </li>
        <li class="clearfix">
          <span class="fl">科目 :</span>
          <el-select v-model="kemu" @change="chosesubject" class="fr">
            <el-option
              v-for="item in subject"
              :key="item.sid"
              :label="item.subject_name"
              :value="item.sid"
            >
            </el-option>
          </el-select>
        </li>
      </ul>
    </div>
    <div>
      <el-tree
        :data="data"
        node-key="id"
        :default-expanded-keys="[num]"
        :props="defaultProps"
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
  </div>
</template>

<script>
import VueEvent from "@/api/eventBus.js";
import URL from "@/api/index";
export default {
  data() {
    return {
      xueduan: "小学",
      kemu: "语文",
      //树形结构 展开数据
      num: "",
      // 学段
      stage: [],
      // 科目
      subject: [],
      // 树形结构
      data: [],
      defaultProps: {
        children: "son",
        label: "point",
      },
    };
  },
  created() {
    var zjsid = JSON.parse(localStorage.getItem("zjsid"));
    if (zjsid != null) {
    } else {
      localStorage.setItem("zjsid", JSON.stringify(1));
    }
    this.xueduan = JSON.parse(localStorage.getItem("zjgrade"));
    if (this.xueduan != null) {
      this.chosegrade1(this.xueduan);
    } else {
      this.xueduan = "小学";
      this.chosegrade1(2);
    }
  },
  mounted() {
    this.getGradeSegment();
  },
  methods: {
    handleNodeClick(data, node) {
      localStorage.setItem("pre_zsdname", JSON.stringify(data.point));
      localStorage.setItem("pre_zsdid", JSON.stringify(data.point_id));
      node.checked = !node.checked;
      this.$emit("zjpointid", data.point_id);
    },
    // 学段切换 初始化
    chosegrade1(e) {
      localStorage.setItem("zjgrade", JSON.stringify(e + ""));
      this.$emit("zjgrade", e);
      this.getSubjectBygsid(e);

      if (JSON.parse(localStorage.getItem("zjsid"))) {
        var texth = { gsid: e, sid: JSON.parse(localStorage.getItem("zjsid")) };
      } else {
        var texth = { gsid: e, sid: "1" };
      }
      this.getPoints(texth); //知识点库
    },
    // 学段切换
    chosegrade(e) {
      localStorage.setItem("zjgrade", JSON.stringify(e + ""));
      localStorage.setItem("zjsid", JSON.stringify(1));

      this.$emit("zjsid", 1);
      this.$emit("zjgrade", e);

      // 获取科目列表
      this.getSubjectBygsid(e);

      var texth = { gsid: e, sid: "1" };
      this.getPoints(texth); //知识点库

      localStorage.removeItem("shiti");
      localStorage.removeItem("listtitle");
      localStorage.removeItem("shitilength");
      VueEvent.$emit("basketlist", { a: 0, b: "" });
    },
    // 科目切换
    chosesubject(e) {
      localStorage.setItem("zjsid", JSON.stringify(e));
      this.$emit("zjsid", e);

      if (JSON.parse(localStorage.getItem("zjgrade"))) {
        var texth = {
          gsid: JSON.parse(localStorage.getItem("zjgrade")),
          sid: e,
        };
      } else {
        var texth = { gsid: "2", sid: e };
      }
      this.getPoints(texth); //知识点库

      localStorage.removeItem("shiti");
      localStorage.removeItem("listtitle");
      localStorage.removeItem("shitilength");
      VueEvent.$emit("basketlist", { a: 0, b: "" });
    },
    // 学段
    getGradeSegment() {
      var that = this;
      this.$post(URL.getGradeSegment).then((res) => {
        that.stage = res.result;
      });
    },
    // 获取科目列表
    getSubjectBygsid(e) {
      var num = JSON.parse(localStorage.getItem("zjsid"));
      var that = this;
      var data = {
        gsid: e,
      };
      this.$post(URL.getSubjectBygsid, data).then((res) => {
        that.subject = res.result;
        if (that.subject.length != 0) {
          that.subject.forEach(function (data, index) {
            if (data.sid == num) {
              that.kemu = that.subject[index].subject_name;
            }
          });
        } else {
          that.kemu = "";
        }
      });
    },
    //tree 树形结构
    getPoints(e) {
      var that = this;
      var data = {
        gsid: e.gsid,
        sid: e.sid,
      };
      this.$post(URL.getPoints, data).then((res) => {
        that.data = res.result;
        that.num = that.data[0].id;
        this.$emit("zjpointid", res.result[0].point_id);
        localStorage.setItem(
          "zjpointid",
          JSON.stringify(res.result[0].point_id)
        );
      });
    },
  },
};
</script>

<style>
.el-select-dropdown__item {
  color: #606266 !important;
  font-weight: 400 !important;
}
</style>
<style lang="less" scoped>
.box_one {
  margin: 24px 0;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(200, 200, 200, 0.47);
  min-height: 556px;
  .box_one_son {
    border-bottom: 1px solid #eeeeee;
    padding: 0 0 15px;
    ul {
      li {
        font-size: 14px;
        color: #707070;
        margin-top: 20px;
        span {
          line-height: 40px;
        }
        /deep/ .el-select {
          width: 75%;
        }
      }
    }
  }
}
</style>
