<template>
  <div>
    <top></top>
    <navMenu></navMenu>
    <div class="inner clearfix">
      <navbar :newpage="newpage"></navbar>
      <knowledge
        class="fl zjleft"
        @zjpointid="zjpointid"
        @zjgrade="zjgrade"
        @zjsid="zjsid"
      ></knowledge>
      <div class="fr zjright">
        
        <filterBox
          @difficulty="difficulty"
          @questionTypes="questionTypes"
          :gettypelist="gettypelist"
          :formatypearr="formatypearr"
        ></filterBox>     
        <hint :listconhttpnum="listconhttpnum" :serradio="serradio"></hint>

        <paper :paperlist="listdispose" v-if="display" id="tiliebiao" v-loading="loading"
          :element-loading-text="this.loadingtext"></paper>
        <div v-else class="zanwu" align="center"><img src="@/assets/image/zanwu.png" alt="" /></div>

        <pagination v-if="listconhttpnum>10" @pagenew="pagenew" :initialize="initialize" :listconhttpnum="listconhttpnum"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import URL from "@/api/index";
import top from "@/components/navtop.vue";
import navMenu from "@/components/menuNav.vue";
import knowledge from "../../components/testPaper/knowledge";
import navbar from "../../components/testPaper/navbar";
import filterBox from "../../components/testPaper/filterBox";
import hint from "../../components/testPaper/hint";
import paper from "../../components/testPaper/paper.vue";
import pagination from "../../components/testPaper/pagination";
export default {
  components: {
    top,
    navMenu,
    knowledge,
    navbar,
    filterBox,
    hint,
    paper,
    pagination,
  },
  data() {
    return {

      display:true,
      
      initialize:'',

      nums:0,
      nums1:0,

      newpage: 1,
      papernum: "",
      serradio: false,
      paperdata: {
        type: "",
        level: "",
        points: "",
        page: "",
        num: "",
      },
      knowledgedata: {
        sid: 1,
        scid: "",
      },
      // 知识点
      paperlist: [],
      listdispose:[],
      listconhttpnum: "",
      gettypelist: [],

      formatypearr: [
        { fmttype_name: "全部", fmttype_id: 0 },
        { fmttype_name: "容易", fmttype_id: 1 },
        { fmttype_name: "较易", fmttype_id: 2 },
        { fmttype_name: "一般", fmttype_id: 3 },
        { fmttype_name: "较难", fmttype_id: 4 },
        { fmttype_name: "困难", fmttype_id: 5 },
      ],

      loading:false,
      loadingtext:'拼命加载中',
    };
  },
  created() {
    var zjsid = JSON.parse(localStorage.getItem("zjsid"));
    if (zjsid != null) {
      this.knowledgedata.sid = zjsid;
    }
  },
  watch: {
    nums(val){
      if (this.paperlist.length == val - this.nums1) {
        this.listdispose = this.paperlist
        localStorage.setItem("paperlist", JSON.stringify(this.paperlist));
      }
    },
    'paperdata.page'(val){
      this.initialize = val
    }
  },
  mounted() {
    this.collectlist()

    //试题兼容
    setTimeout(() => {
        this.$nextTick(function () {
            if(this.commonsVariable.isMathjaxConfig){
                this.commonsVariable.initMathjaxConfig();
            }
            // this.commonsVariable.MathQueue("app");//传入组件id，让组件被MathJax渲染
        })
    },1000)

    // 数据上报
    var data = {path:'知识点选题',id:'2'}
    localStorage.setItem('optionwork',JSON.stringify(data))
  },
  methods: {
    // 收藏总列表
    collectlist(){
      var that = this
      var data = {
        token:JSON.parse(localStorage.getItem("loginInfo")).token,
        question_type:'',
        question_level: '',
        point_id:'',
        chapter_id:'',
      }
      this.$post(URL.GetQuestionList, data).then((res) => {
        if(res.result != ''){
          localStorage.setItem('collect', JSON.stringify(res.result))
        }
      })
    },
    //知识点题型筛选
    zjgrade(val) {
      this.knowledgedata.scid = val;
      this.getQuestionType(this.knowledgedata);
    },
    zjsid(val) {
      this.knowledgedata.sid = val;
      this.getQuestionType(this.knowledgedata);
    },
    //知识点试题筛选
    zjpointid(val) {
      // 选题id
      this.paperdata.points = val;
      this.nums = 0
      this.nums1 = 0
      this.display = true
      this.paperdata.page = 1
      this.knowledgelistconhttp(this.paperdata);
    },
    difficulty(val) {
      // 难度
      this.paperdata.level = val;
      this.nums = 0
      this.nums1 = 0
      this.display = true
      this.paperdata.page = 1
      this.knowledgelistconhttp(this.paperdata);
    },
    questionTypes(val) {
      // 题型
      this.paperdata.type = val;
      this.nums = 0
      this.nums1 = 0
      this.display = true
      this.paperdata.page = 1
      this.knowledgelistconhttp(this.paperdata);
    },
    pagenew(val) {
      // 页码
      this.paperdata.page = val;
      this.nums = 0
      this.nums1 = 0
      this.display = true
      this.knowledgelistconhttp(this.paperdata);
    },
    getQuestionType(e) {
      //获取知识点题库题型
      var data = {
        sid: e.sid,
        scid: e.scid,
      };
      this.$post(URL.getQuestionType, data).then((res) => {
        this.gettypelist = [];
        this.gettypelist = this.gettypelist
        .concat(
          { type_id: 0, type_name: "全部" },
        )
        .concat(res.result);
      });
    },
    // 知识点试题列表
    knowledgelistconhttp(e) {
      var that = this;
      var data = {
        type: e.type,
        level: e.level,
        points: e.points,
        page: e.page,
        num: e.num,
      };
      this.$post(URL.getPointsQues, data).then((res) => {
    
        that.listconhttpnum = res.result.total;
        that.paperlist = res.result.rows;
        
        // 为空时提示
        if(that.paperlist == 0){
          that.display = false
        }

        // html地址解析
        that.paperlist.forEach(function (data, index) {
          // store_type 1新 2旧题 判断
          var html = data.question_text
          
          var urltext2 = "http://rjwtiku.oss-cn-beijing.aliyuncs.com"

          if (data.store_type == 2) {
            // console.log('旧题')
            var urltext = html.match(/(\S*)SYS/)[1]
            that.$get(data.answer).then((res1) => {
              var reg = new RegExp('src="', "g")
              var realurl2 = res1.replace(reg, 'src="' + urltext)
              that.paperlist[index].answer = realurl2
              that.nums++
            })

            that.$get(data.question_text).then((res2) => {
              var reg = new RegExp('src="', "g")
              var realurl2 = res2.replace(reg, 'src="' + urltext)
              that.paperlist[index].question_text = realurl2
              that.nums++
            })

            that.nums1++
          } else {
            // console.log('新题')

            //文本题目 //图片路径重新处理
            var reg = new RegExp('src="','g')
            // 题目
            var question_text = data.question_text.replace(reg, 'src="' + urltext2)
            // 选项
            var options_json = data.options_json.replace(reg, 'src="' + urltext2)
            // 答案
            var answer = data.answer.replace(reg, 'src="' + urltext2)
            // 解析
            var analysis = data.analysis.replace(reg, 'src="' + urltext2) 
            
            // console.log(that.paperlist[index].question_text)
            
            that.paperlist[index].question_text = question_text
            that.paperlist[index].options_json = options_json

            that.paperlist[index].analysis = analysis
            if(analysis){

            } else {
              that.paperlist[index].analysis = '略'
            }
            that.paperlist[index].answer = "<div>答案:</div>"+ answer + "<div>解析:</div>" + that.paperlist[index].analysis

            that.nums++
          }
          //题型难度  转换
          that.gettypelist.forEach(function (data1) {
            if (data.question_type == data1.type_id) {
              that.paperlist[index].question_type = data1.type_name;
            }
          });
          that.formatypearr.forEach(function (data2) {
            if (data.question_level == data2.fmttype_id) {
              that.paperlist[index].question_level = data2.fmttype_name;
            }
          });
          // MathJax.Hub.Queue(['Typeset', MathJax.Hub])
          // window.MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById('homeright')]);
        });
      });
    },
  },
};
</script>

<style lang='less'>
.zjleft {
  width: 19%;
}
.zjright {
  width: 77%;
}
.zanwu{
  height: 250px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(200, 200, 200, 0.47);
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    height: 50%;
  }
}
</style>