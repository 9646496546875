<template>
<!-- filterBox 筛选框 -->
    <div class="condition">
        <ul>
            <li class="clearfix">
                <span class="fl">题型 :</span>
                <ul class="fl">
                    <li class="fl" v-for="(res,index) in gettypelist" :key="index" @click="questionTypesbtn(res.type_id)" :class="questionTypes == res.type_id?'active':''" >{{res.type_name}}</li>
                </ul>
            </li>
            <li class="clearfix">
                <span class="fl">难度 :</span>
                <ul class="fl">
                    <li class="fl" v-for="res in formatypearr" :key="res.fmttype_id" @click="difficultybtn(res.fmttype_id)" :class="difficulty == res.fmttype_id?'active':''" >{{res.fmttype_name}}</li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props:['gettypelist','formatypearr','gettypelistbut','present'],
    data(){
        return{
            // 难度
            difficulty:0,
            questionTypes:0
        }
    },
    watch:{
        // present(val,old){
        //     if(val != old){
        //         this.questionTypesbtn(0)
        //         this.difficultybtn(0)
        //     }
        // }
    },
    mounted(){

    },
    created(){
        // var questionTypesbtn = JSON.parse(localStorage.getItem("questionTypesbtn"))
        // if(questionTypesbtn != null){
        //     this.questionTypesbtn(questionTypesbtn)
        // }
        // var difficultybtn = JSON.parse(localStorage.getItem("difficultybtn"))
        // if(difficultybtn != null){
        //     this.difficultybtn(difficultybtn)
        // }
    },
    methods:{
        questionTypesbtn(e){
            this.questionTypes = e
            this.$emit('questionTypes',this.questionTypes)
            localStorage.setItem("questionTypesbtn", JSON.stringify(e))
        },
        difficultybtn(e){
            this.difficulty = e
            this.$emit('difficulty',this.difficulty)
            localStorage.setItem("difficultybtn", JSON.stringify(e))
        },
        
    }
}
</script>

<style lang="less" scoped>
.condition{
    margin:24px 0;
    background: #fff;
    box-shadow: 0px 0px 6px 0px 
		rgba(200, 200, 200, 0.47);
    ul{
        width: 90%;
        padding: 10px 20px;
        li{
            line-height: 50px;
            font-size: 14px;
            ul{
                padding:0 20px;
                li{
                    margin: 10px 0;
                    padding: 0 15px;
                    line-height: 30px;
                    font-weight: 400;
                    text-align: center;
                    border-radius: 30px;
                    cursor: pointer;
                }
            }
        }
    }
}
.active{
    background-color: #3a8afd;
    color: #fff;
}
</style>
